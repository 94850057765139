export const TRANSLATIONS_DE_CONTAINER = {
    ABBRECHEN: "Abbrechen",
    ABFRAGE_AUSSTATTUNG_ARCHIVIEREN:
        "Möchten Sie die Ausstattung wirklich löschen?",
    ABFRAGE_BUCHUNG_STONIEREN: "Möchten Sie die Buchung wirklich stornieren?",
    ABFRAGE_BUCHUNGSUNTERLAGE_ARCHIVIEREN:
        "Möchten Sie die Buchungsunterlage wirklich löschen?",
    ABFRAGE_CONTAINER_ARCHIVIEREN:
        "Möchten Sie den Container wirklich löschen?",
    ABFRAGE_CONTAINER_BUCHUNGSUNTERLAGE_ARCHIVIEREN:
        "Möchten Sie die Containerbuchungsunterlage wirklich löschen?",
    ABFRAGE_CONTAINERBURG_ARCHIVIEREN:
        "Möchten Sie die Containerburg wirklich löschen?",
    ABFRAGE_CONTAINERUNTERLAGE_ARCHIVIEREN:
        "Möchten Sie die Unterlage wirklich löschen?",
    ABFRAGE_KUENDIGUNG_BESTAETIGEN:
        "Möchten Sie folgende Container wirklich kündigen? Dann bestätigen Sie dieses mit Klick auf den Button.",
    ABRECHNUNGSDETAILS: "Abrechnungsdetails",
    ABRECHNUNGSDETAILS_BUCHUNGEN:
        "Buchung von {{containerAnzahl}} Container im Zeitraum {{buchungVon}} - {{buchungBis}}",
    ABRECHNUNGSDETAILS_EXPORTIEREN: "Abrechnungsdetails exportieren",
    ABRECHNUNG_UEBER_BAULOGISTIK: "Abrechnung über Baulogistiker",
    AKTUELL_NICHT_GENEHMIGEN: "Aktuell nicht genehmigen",
    ALLE: "Alle",
    ANSPRECHPARTNER_BUCHUNG: "Ansprechpartner der Buchung",
    ANSPRECHPARTNER_AKTUALISIEREN: "Ansprechpartner aktualisieren",
    ANSPRECHPARTNER_ERFOLGREICH_AKTUALISIERT:
        "Ansprechpartner erfolgreich aktualisiert",
    ANSPRECHPARTNER_KONFIGURIEREN: "Ansprechpartner",
    ANZAHL: "Anzahl",
    ANZAHL_KOPIEN: "Kopienanzahl",
    AUSGEWAEHLTE_UNTERLAGE: "Ausgewählte Unterlage",
    AUSSTATTUNG: "Ausstattung",
    AUSSTATTUNG_ANZAHL_EINGEBEN:
        "Hier kann die Anzahl der Ausstattung eingeben werden",
    AUSSTATTUNG_ARCHIVIEREN: "Ausstattung löschen",
    AUSSTATTUNG_AKTUALISIEREN: "Ausstattung aktualisieren",
    AUSSTATTUNG_ERFOLGREICH_AKTUALISIERT:
        "Ausstattung erfolgreich aktualisiert.",
    AUSSTATTUNG_ERFOLGREICH_ARCHIVIERT: "Ausstattung erfolgreich gelöscht.",
    AUSSTATTUNG_ERFOLGREICH_ERSTELLT: "Ausstattung erfolgreich erstellt.",
    AUSSTATTUNG_ERSTELLEN: "Ausstattung erstellen",
    AUSSTATTUNG_ID_NICHT_GEFUNDEN:
        "Ausstattung-Id konnte nicht gefunden werden.",
    AUSSTATTUNG_INFO: "Information zu den Aussattungen",
    AUSSTATTUNG_KONFIGURIEREN: "Ausstattung",
    AUSSTATTUNG_LOESCHEN: "Ausstattung löschen",
    AUSSTATTUNG_NAME: "Ausstattungsname",
    AUSSTATTUNGSANZAHL: "Ausstattungsanzahl",
    AUSSTATTUNGSANZAHL_GEBUCHT: "Bereits gebuchte Ausstattung gesamt",
    AUSSTATTUNGSANZAHL_GESAMT: "Konfigurierte Ausstattungsanzahl gesamt",
    AUSSTATTUNGSANZAHL_PRO_CONTAINER: "Ausstattungsanzahl pro Container",
    BENUTZER_MAIL: "Email",
    BENUTZER_NACHNAME: "Nachname",
    BENUTZER_TELEFONNUMMMER: "Telefonnummer",
    BENUTZER_TELEFONNUMMER_EINGEBEN: "Bitte Telefonnummer eingeben",
    BENUTZER_UNTERNEHMEN: "Unternehmen",
    BENUTZER_VORNAME: "Vorname",
    BESTAETIGE_AENDERUNGEN: "Bestätige die Änderung der Buchung",
    BILD: "Bild",
    BITTE_AUSSTATTUNGEN_WAEHLEN: "Ausstattung bitte auswählen",
    BITTE_CONTAINERBURG_WAEHLEN: "Containerburg bitte auswählen",
    BITTE_CONTAINER_WAEHLEN: "Container bitte auswählen",
    BITTE_CONTAINERTYP_WAEHLEN: "Containertyp bitte auswählen",
    BEREITS_GENEHMIGT: "Container bereits genehmigt",
    BEREITS_GEKUENDIGT: "Container bereits zum {{datum}} gekündigt",
    BLOCKIERT: "Blockiert",
    BUCHUNG: "Buchung",
    BUCHUNG_AKTUALISIEREN: "Buchung aktualisieren",
    BUCHUNG_BEARBEITEN: "Korrigiere die Buchungseingaben",
    BUCHUNG_BESTAETIGEN: "Buchung bestätigen",
    BUCHUNG_BIS: "Buchung bis",
    BUCHUNG_BIS_AUSWAEHLEN: "Buchung bis auswählen",
    BUCHUNG_BIS_GETRENNT: "Buchung <br> bis",
    BUCHUNG_CONTAINER: "Buchung des Container",
    BUCHUNG_DETAILS: "Buchungdetails",
    BUCHUNG_ERFOLGREICH_AKTUALISIERT: "Buchung erfolgreich aktualisiert",
    BUCHUNG_ERFOLGREICH_BESTAETIGT: "Buchung erfolgreich bestätigt.",
    BUCHUNG_ERFOLGREICH_ERSTELLT: "Buchung erfolgreich erstellt",
    BUCHUNG_ERFOLGREICH_GENEHMIGT: "Buchung erfolgreich genehmigt",
    BUCHUNG_ERFOLGREICH_STORNIERT: "Buchung erfolgreich storniert",
    BUCHUNG_ERFOLGREICH_VERLAENGERT: "Buchung erfolgreich verlängert",
    BUCHUNG_ERSTELLEN: "Buchung erstellen",
    BUCHUNG_GENEHMIGEN: "Bitte bestätigen Sie die Buchungsanfrage",
    BUCHUNG_KUENDIGEN: "Buchung kündigen",
    BUCHUNG_STORNIEREN: "Buchung stornieren",
    BUCHUNG_VERLAENGERN: "Buchung verlängern",
    BUCHUNG_VON: "Buchung von",
    BUCHUNG_VON_AUSWAEHLEN: "Buchung von auswählen",
    BUCHUNG_VON_GETRENNT: "Buchung <br>von",
    BUCHUNGEN: "Buchungen",
    BUCHUNGEN_ANZEIGEN_TITLE: "Buchungen anzeigen",
    BUCHUNGEN_UEBERSICHT: "Buchungen-Übersicht",
    BUCHUNGS_ID_NICHT_GEFUNDEN: "Buchungs-Id konnte nicht gefunden werden",
    BUCHUNGS_UEBERSICHT: "Buchungsübersicht",
    BUCHUNGSANFRAGEN_VERWALTEN: "Buchungsanfragen verwalten",
    BUCHUNGSDATEN: "Buchungsdaten",
    BUCHUNGSDATUM_GETRENNT: "Buchungs<br>datum",
    BUCHUNGSNAME: "Buchungsname",
    BUCHUNGSANFRAGE_ABSCHICKEN: "Buchungsanfrage abschicken",
    BUCHUNGSANFRAGE_ERFOLGREICH_ERSTELLT:
        "Buchungsanfrage wurde erfolgreich erstellt.",
    BUCHUNGSANFRAGE_ERSTELLEN: "Buchungsanfrage erstellen",
    BUCHUNGSTATUS: "Buchungstatus",
    BUCHUNGSTATUS_GETRENNT: "Buchungs<br>status",
    BUCHUNGSUNTERLAGE_AENDERN: "Buchungsunterlage ändern",
    BUCHUNGSUNTERLAGE_ARCHIVIEREN: "Buchungsunterlage archivieren",
    BUCHUNGSUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Buchungsunterlage wurde erfolgreich aktualisiert.",
    BUCHUNGSUNTERLAGE_ERFOLGREICH_ARCHIVIERT:
        "Buchungsunterlage wurde erfolgreich gelöscht.",
    BUCHUNGSUNTERLAGE_ERFOLGREICH_ERSTELLT:
        "Buchungsunterlage wurde erfolgreich erstellt.",
    BUCHUNGSUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Buchungsunterlage wurde erfolgreich aktualisiert.",
    BUCHUNGSUNTERLAGE_HINZUFUEGEN: "Buchungsunterlage hinzufügen",
    BUCHUNGSUNTERLAGEN: "Buchungsunterlagen",
    BUCHUNGSUNTERLAGEN_KONFIGURIEREN: "Buchungsunterlagen",
    BUCHUNGSZEITRAUM: "Buchungszeitraum",
    CONTAINER_AKTUALISIEREN: "Container aktualisieren",
    CONTAINER_BEZEICHNUNG: "Container bezeichnung",
    CONTAINER_BUCHEN: "Container buchen",
    CONTAINER_BUCHUNGSUNTERLAGEN: "Containerbuchungsunterlagen",
    CONTAINER_BUCHUNGSUNTERLAGE_AENDERN: "Containerbuchungsunterlage ändern",
    CONTAINER_BUCHUNGSUNTERLAGE_ANZEIGEN: "Containerbuchungsunterlage anzeigen",
    CONTAINERBUCHUNGSUNTERLAGE_ERFOLGREICH_ARCHIVIERT:
        "Containerbuchungsunterlage erfolgreich gelöscht",
    CONTAINERBUCHUNGSUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Containerbuchungsunterlage erfolgreich aktualisiert",
    CONTAINER_BUCHUNGSUNTERLAGEN_KONFIGURIEREN: "Containerbuchungs-unterlagen",
    CONTAINER_BUCHUNGSUNTERLAGEN_KONFIGURIEREN_TITLE:
        "Containerbuchungsunterlagen konfigurieren",
    CONTAINER_DATEN: "Containerdaten",
    CONTAINER_DETAILS: "Container details",
    CONTAINER_ENTFERNTER_EBENE: "Container der entfernten Ebene löschen",
    CONTAINER_ERFOLGREICH_AKTUALISIERT: "Container erfolgreich aktualisiert",
    CONTAINER_ERFOLGREICH_ARCHIVIERT: "Container wurde gelöscht.",
    CONTAINER_ERFOLGREICH_ERSTELLT: "Container wurde erfolgreich erstellt.",
    CONTAINER_ERFOLGREICH_GEKUENDIGT: "Container wurden erfolgreich gekündigt",
    CONTAINER_ARCHIVIEREN: "Container löschen",
    CONTAINER_GENEHMIGEN: "Container genehmigen",
    CONTAINER_HINZUFUEGEN: "Container hinzufügen",
    CONTAINER_ID_NICHT_GEFUNDEN: "Container-Id konnte nicht gefunden werden",
    CONTAINER_KUENDIGEN: "Container kündigen",
    CONTAINER_KONFIGURIEREN: "Container",
    CONTAINER_MODUL: "Container-Modul",
    CONTAINER_NAME: "Containername",
    CONTAINER_NAME_GETRENNT: "Container<br>name",
    CONTAINER_STATUS_GETRENNT: "Container<br>status",
    CONTAINER_TYP: "Containertyp",
    CONTAINER_TYP_GETRENNT: "Container<br>typ",
    CONTAINER_UEBERSICHT: "Container-Übersicht",
    CONTAINER_UNTERLAGEN_KONFIGURIEREN: "Informations- unterlagen",
    CONTAINER_VERLAENGERN: "Container verlängern",
    CONTAINERANZAHL: "Containeranzahl",
    CONTAINERANZAHL_GETRENNT: "Container- <br> anzahl",
    CONTAINERBILD_ANZEIGEN: "Bild anzeigen",
    CONTAINERBUCHUNGEN: "Containerbuchungen",
    CONTAINERBURG: "Containerburg",
    CONTAINERBURG_AKTUALISIEREN: "Containerburg aktualisieren",
    CONTAINERBURG_ARCHIVIEREN: "Containerburg archivieren",
    CONTAINERBURG_BEZEICHNUNG: "Containerburg-Bezeichnung",
    CONTAINERBURG_ERFOLGREICH_AKTUALISIERT:
        "Containerburg erfolgreich aktualisiert",
    CONTAINERBURG_ERFOLGREICH_ARCHIVIERT:
        "Containerburg erfolgreich archiviert",
    CONTAINERBURG_ERFOLGREICH_ERSTELLT:
        "Containerburg wurde erfolgreich erstellt.",
    CONTAINERBURG_EXPORTIEREN: "Containerburg exportieren",
    CONTAINERBURG_HINZUFUEGEN: "Containerburg hinzufügen",
    CONTAINERBURG_ID_NICHT_GEFUNDEN:
        "Containerburg-Id konnte nicht gefunden werden",
    CONTAINERBURG_KONFIGURIEREN: "Containerburg",
    CONTAINERBURG_NUMMER: "Nummer",
    CONTAINERBURGEN: "Containerburgen",
    CONTAINEREINHEIT: "Containereinheit",
    CONTAINERNAME: "Containername",
    CONTAINERNUMMER_LIEFERANT: "Container-Nummer (Lieferant)",
    CONTAINERTYP: "Containertyp",
    CONTAINER_LOESCHEN: "Container löschen",
    CONTAINERUNTERLAGE_AENDERN: "Containerunterlage ändern",
    CONTAINERUNTERLAGE_AKTUALISIEREN: "Containerunterlage aktualisieren",
    CONTAINERUNTERLAGE_ANZEIGEN: "Containerunterlage anzeigen",
    CONTAINERUNTERLAGE_ARCHIVIEREN: "Containerunterlage archivieren",
    CONTAINERUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Containerunterlage erfolgreich aktualisiert",
    CONTAINERUNTERLAGE_ERFOLGREICH_ARCHIVIERT:
        "Containerunterlage erolgreich gelöscht.",
    CONTAINERUNTERLAGE_ERFOLGREICH_ERSTELLT:
        "Containerunterlage erfolgreich erstellt",
    CONTAINERUNTERLAGE_HINZUFUEGEN: "Containerunterlage hinzufügen",
    DATEI_AUSWAEHLEN: "Datei auswählen",
    DOKUMENT: "Dokument",
    DOKUMENT_ARCHIVIEREN: "Dokument löschen",
    DOKUMENT_MODAL_ARCHIVIEREN: "Möchten Sie das Dokument wirklich löschen?",
    DOWNLOADNAME: "Downloadname",
    EBENE: "Ebene",
    EBENEN_ANZAHL: "Anzahl der Ebenenen",
    EINSTELLUNGEN: "Einstellungen",
    EMAIL: "Emailadresse",
    EUFECB_ERFOLGREICH_ERSTELLT:
        "Containerbuchungsunterlage erfolgreich erstellt",
    EXPORT_TOAST:
        "Der Download wurde gestartet und läuft im Hintergrund während Ihrer Tätigkeiten in ALGO weiter. Bitte schließen Sie derweil ALGO nicht.",
    FEHLENDE_RECHTE: "Fehlende Berechtigungen",
    FREI: "Frei",
    GENEHMIGE_CONTAINER: "Ausgewählte Container genehmigen",
    GRUENDE_DER_AENDERUNG: "Gründe der Änderung",
    GRUENDE_DER_BUCHUNG: "Gründe der Buchung",
    GRUENDE_DER_STORNIERUNG: "Gründe der Stornierung",
    GRUENDE_DER_VERLAENGERUNG: "Gründe der Verlängerung",
    HILFE_TEXT_BLAU: "Sanitär-Container",
    HILFE_TEXT_GELB: "Container wurde bereits gekündigt und wird wieder frei",
    HILFE_TEXT_GRUEN: "Container ist frei verfügbar",
    HILFE_TEXT_LINKS: "Linke Seite der Ebene",
    HILFE_TEXT_ROT: "Gebuchte Container",
    HILFE_TEXT_RECHTS: "Rechte Seite der Ebene",
    HILFE_TEXT_WEISS: "Container von Admin blockiert",
    HOCHGELADENE_UNTERLAGE: "Hochgeladene Unterlage",
    INFO_TITEL: "Informationen zur Containerburgansicht",
    INVALID_FORM: "Fülle alle Eingabefelder aus.",
    JA: "Ja",
    KEIN_BILD_VORHANDEN: "Kein Bild vorhanden",
    KEIN_PDF_FORMAT: "Bitte laden Sie PDF-Format hoch",
    KEINE_AUSSTATTUNG: "Keine Ausstattung vorhanden",
    KEINE_CONTAINER_ZU_GENEHMIGEN: "Keine Container zu genehmigen",
    KEINE_CONTAINER_ZU_KUENDIGEN: "Keine Container zu kündigen",
    KEINE_CONTAINER_ZU_VERLAENGERN: "Keine container zu verlängern",
    KEINE_SONDERAUSSTATTUNG: "Keine Sonderausstattung gebucht",
    KEINE_UNTERLAGEN: "Keine Unterlagen vorhanden",
    KEINE_VORLAGE_HOCHGELADEN: "Keine Vorlage hochgeladen",
    KONTAKTDATEN: "Kontaktdaten der Container-Verwaltung",
    KONTAKT_DATEN: "Kontaktdaten",
    KONTAKTDATEN_BUCHUNG: "Kontaktdaten der Buchung",
    KONTAKTDATEN_GETRENNT: "Kontakt<br>daten",
    KONTAKTPERSON: "Kontaktperson",
    KUENDIGUNG_BESTAETIGEN: "Kündigung bestätigen",
    KUENGIGUNG_BIS_AUSWAEHLEN: "Kündigung bis",
    KUENDIGUNGSTERMIN: "Kündigungstermin",
    LEISTUNGSVERZEICHNIS_NUMMER: "LV-Nr",
    LINKE_SEITE: "Linke Seite",
    LINKER_NACHBAR: "Linker Nachbar",
    LV_NUMMER: "LV-Nr.",
    MAIL_AN_BENUTZER: "Mail an buchende Person",
    MIETGEBUEHREN: "Mietgebühren",
    MODULVERANTWORTLICHER_MAIL: "Mail des Verantwortlichen",
    MODULVERANTWORTLICHER_TELEFONNUMMER: "Telefonnummer des Verantwortlichen",
    NACHRICHT: "Nachricht",
    NACHRICHT_MAIL:
        "Nachricht wird in der Email mit versandt und gilt als Information für den Buchenden oder Modulverantwortlichen",
    NACHRICHT_ZUR_GENEHMIGUNG: "Nachricht zur Genehmigung",
    NACHRICHT_ZUR_KUENDIGUNG: "Nachricht zur Kündigung",
    NACHRICHT_ZUR_VERLAENGERUNG: "Nachricht zur Verlängerung",
    NAME: "Name",
    NAME_EINGEBEN: "Bitte Name eingeben",
    NEIN: "Nein",
    PERSONEN: "Personen",
    EUFEB_ANZEIGEN: "Buchungsunterlage als PDF-Datei anzeigen",
    EUFECB_ANZEIGEN: "Containerbuchungsunterlage als PDF-Datei anzeigen",
    EUFEB_HOCHLADEN: "Buchungsunterlage als PDF-Datei hochladen",
    EUFECB_HOCHLADEN: "Containerbuchungsunterlage als PDF-Datei hochladen",
    PERSONENANZAHL: "Personenanzahl",
    PERSONENANZAHL_GETRENNT: "Personen<br>anzahl",
    RECHNUNGSADRESSE: "Rechnungsadresse",
    RECHNUNGSDATEN: "Rechnungsdaten",
    RECHNUNG_PER_MAIL: "Rechnung per Mail empfangen",
    RECHNUNGS_STRASSE: "Straße",
    RECHNUNGS_STADT: "Stadt",
    RECHNUNGS_POSTLEITZAHL: "Postleitzahl",
    RECHTE_SEITE: "Rechte Seite",
    RECHTER_NACHBAR: "Rechter Nachbar",
    SEITE: "Seite",
    SONDERAUSSTATTUNG: "Sonderausstattung",
    SONDERAUSSTATTUNG_INFO_CONTENT:
        "Die folgenden Ausstattungen sind Sonderausstattungen und sind mit Kosten verbunden:",
    STANDARD_AUSSTATTUNG_INFO_CONTENT:
        "Die folgenden Ausstattungen sind Standardausstattungen und damit bei allen Containern vorhanden: ",
    SONDERAUSSTATTUNG_GETRENNT: "Sonder- ausstattung?",
    STATUS: "Status",
    STATUS_AUSWAEHLEN: "Bitte Status auswählen",
    TELEFONNUMMER: "Telefonnummer",
    TYP: "Typ",
    UNTERNEHMEN: "Unternehmen",
    UNTERLAGE_ERFOLGREICH_ARCHIVIERT: "Unterlage erfolgreich gelöscht",
    UNTERLAGE_ERFOLGREICH_HINZUGEFUEGT: "Unterlage erfolgreich hinzugefügt.",
    UNTERLAGE_HINZUFUEGEN: "Unterlage hinzufügen",
    UNTERLAGE_HOCHLADEN: "Unterlage hochladen",
    UNTERLAGE_NICHT_GEFUNDEN: "Unterlage konnte nicht gefunden werden",
    UNTERLAGEN: "Unterlagen",
    UNTERLAGEN_ID_NICHT_GEFUNDEN: "Unterlagen-Id konnte nicht gefunden werden",
    VERBINDLICHE_BUCHUNGSABFRAGE: "Verbindliche Buchungsanfrage abschicken",
    VERBINDLICHE_BUCHUNGSABFRAGE_CONTENT:
        "Durch die Anfrage entstehen für Sie Mietgebühren, welche Sie den Baustellenunterlagen, entnehmen können.",
    VERBINDLICHE_BUCHUNGSABFRAGE_ABSCHICKEN_CONTENT:
        "Möchten Sie die diese Buchungsanfrage verbindlich abschicken?",
    VERBINDLICHE_MODULVERANTWORTLICHEN_CONTENT:
        "Die Buchung muss noch vom Modulverantwortlichen genehmigt werden.",
    VERBINDLICHE_MODULVERANTWORTLICHEN_ZUSAGE_CONTENT:
        "Erst nach Genehmigung erhalten Sie eine Zusage für die Buchungen der Container sowie der Sonderausstattung.",
    VERBINDLICHE_MODULVERANTWORTLICHEN_ZUSAGE_CONTENT_OHNE:
        "Erst nach Genehmigung erhalten Sie eine Zusage für die Buchungen der Container.",
    VERBINDLICHE_SONDERAUSSTATTUNG_CONTENT:
        "Durch die Eintragung von Sonderausstattung können Kosten entstehen, welche Sie den Buchungsunterlagen entnehmen können.",
    VERLAENGERN_BESTAETIGEN: "Verlängern bestätigen",
    VERLAENGERN_BIS_AUSWAEHLEN: "Verlängern bis",
    VERSAND_PER_MAIL: "Versand per E-Mail",
    VERSAND_PER_POST: "Versand per Post",
    VORLAGE: "Vorlage",
    VORLAGE_ANZEIGEN: "Vorlage anzeigen",
    WEITERE_UNTERLAGE_HOCHLADEN: "Weitere Unterlage hochladen",
    WEITERE_UNTERLAGE_HOCHLADEN_TITLE:
        "Weitere Unterlage, welche oben nicht abgefragt wird",
    WIRD_NACHGEREICHT: "wird nachgereicht",
    ZEITRAUM: "Zeitraum",
    ZEITRAUM_AUSWAEHLEN: "Zeitraum auswählen",
    ZEITRAUM_EINSCHRAENKUNG: "Zeitraum einschränken",
    ZURUECK: "Zurück",
};
