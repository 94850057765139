export const TRANSLATIONS_EN_CONTAINER = {
    ABBRECHEN: "Cancel",
    ABFRAGE_AUSSTATTUNG_ARCHIVIEREN:
        "Are you sure you want to delete the equipment?",
    ABFRAGE_BUCHUNG_STONIEREN: "Are you sure you want to cancel the booking?",
    ABFRAGE_BUCHUNGSUNTERLAGE_ARCHIVIEREN:
        "Are you sure you want to delete the booking document?",
    ABFRAGE_CONTAINER_ARCHIVIEREN:
        "Are you sure you want to delete the container?",
    ABFRAGE_CONTAINERBURG_ARCHIVIEREN:
        "Are you sure you want to delete the container castle?",
    ABFRAGE_CONTAINER_BUCHUNGSUNTERLAGE_ARCHIVIEREN:
        "Are you sure you want to delete the container booking document?",
    ABFRAGE_CONTAINERUNTERLAGE_ARCHIVIEREN:
        "Are you sure you want to delete the document?",
    ABFRAGE_KUENDIGUNG_BESTAETIGEN:
        "Do you really want to cancel the following containers? Then confirm this by clicking on the button.",
    ABRECHNUNGSDETAILS: "Accounting details",
    ABRECHNUNGSDETAILS_BUCHUNGEN:
        "Booking of {{containerAnzahl}} containers in the period {{buchungVon}} - {{buchungBis}}",
    ABRECHNUNGSDETAILS_EXPORTIEREN: "Export accounting details",
    ABRECHNUNG_UEBER_BAULOGISTIK: "Settlement via construction logistician",
    AKTUELL_NICHT_GENEHMIGEN: "Not approved actually",
    ALLE: "All",
    ANSPRECHPARTNER_BUCHUNG: "Contact person of booking",
    ANSPRECHPARTNER_AKTUALISIEREN: "Update contact person",
    ANSPRECHPARTNER_ERFOLGREICH_AKTUALISIERT:
        "Contact person was updated successfully",
    ANSPRECHPARTNER_KONFIGURIEREN: "Contact person",
    ANZAHL: "Count",
    ANZAHL_KOPIEN: "Copy of Count",
    AUSGEWAEHLTE_UNTERLAGE: "Selected document",
    AUSSTATTUNG: "Equipment",
    AUSSTATTUNG_ANZAHL_EINGEBEN: "The number of equipment can be entered here.",
    AUSSTATTUNG_AKTUALISIEREN: "Update equipment",
    AUSSTATTUNG_ARCHIVIEREN: "Delete equipment",
    AUSSTATTUNG_ERFOLGREICH_AKTUALISIERT: "Equipment was updated succesfully.",
    AUSSTATTUNG_ERFOLGREICH_ARCHIVIERT: "Equipment was deleted succesfully.",
    AUSSTATTUNG_ERFOLGREICH_ERSTELLT: "Equipment was created successfully.",
    AUSSTATTUNG_ERSTELLEN: "Create Equipment",
    AUSSTATTUNG_ID_NICHT_GEFUNDEN: "Id of equipment couldn't found.",
    AUSSTATTUNG_INFO: "Information of equipment",
    AUSSTATTUNG_KONFIGURIEREN: "Equipment",
    AUSSTATTUNG_LOESCHEN: "Delete equipment",
    AUSSTATTUNG_NAME: "Equiptment name",
    AUSSTATTUNGSANZAHL: "Number of equipment",
    AUSSTATTUNGSANZAHL_GESAMT: "Total number of equipment configured",
    AUSSTATTUNGSANZAHL_VERGEBEN: "Total number of equipment assigned",
    BENUTZER_MAIL: "Mail",
    BENUTZER_NACHNAME: "Lastname",
    BENUTZER_TELEFONNUMMMER: "Phonenumber",
    BENUTZER_TELEFONNUMMER_EINGEBEN: "Please enter your phone number",
    BENUTZER_UNTERNEHMEN: "Company",
    BENUTZER_VORNAME: "Firstname",
    BESTAETIGE_AENDERUNGEN: "Please confirm the change of booking",
    BEREITS_GENEHMIGT: "Container already approved",
    BEREITS_GEKUENDIGT: "Container already canceled",
    BILD: "Image",
    BITTE_AUSSTATTUNGEN_WAEHLEN: "Please select equipment",
    BITTE_CONTAINERBURG_WAEHLEN: "Please select container castle",
    BITTE_CONTAINER_WAEHLEN: "Please select container",
    BITTE_CONTAINERTYP_WAEHLEN: "Please select container type",
    BLOCKIERT: "Blocked",
    BUCHUNG: "Booking",
    BUCHUNG_BEARBEITEN: "Edit the period of booking",
    BUCHUNG_BESTAETIGEN: "Confirm the booking",
    BUCHUNG_BIS: "End of booking",
    BUCHUNG_BIS_AUSWAEHLEN: "Select end of booking",
    BUCHUNG_BIS_GETRENNT: "End of<br> booking",
    BUCHUNG_CONTAINER: "Booking of container",
    BUCHUNG_DETAILS: "Details of booking",
    BUCHUNG_ERFOLGREICH_AKTUALISIERT: "Booking was updated successfully",
    BUCHUNG_ERFOLGREICH_BESTAETIGT: "Booking was confirmed successfully.",
    BUCHUNG_ERFOLGREICH_ERSTELLT: "Booking was created successfully.",
    BUCHUNG_ERFOLGREICH_GENEHMIGT: "Booking was approved successfully.",
    BUCHUNG_ERFOLGREICH_STORNIERT: "Booking was canceled successfully.",
    BUCHUNG_ERFOLGREICH_VERLAENGERT: "Booking was extended succesfully.",
    BUCHUNG_ERSTELLEN: "Create book",
    BUCHUNG_GENEHMIGEN: "Pleas confirm the request of booking",
    BUCHUNG_KUENDIGEN: "Cancel booking",
    BUCHUNG_STORNIEREN: "Cancel the booking",
    BUCHUNG_VERLAENGERN: "Extend booking",
    BUCHUNG_VON: "Begin of booking",
    BUCHUNG_VON_AUSWAEHLEN: "Select begin of booking",
    BUCHUNG_VON_GETRENNT: "Begin of <br>booking",
    BUCHUNGEN: "Bookings",
    BUCHUNGEN_ANZEIGEN_TITLE: "Show bookings",
    BUCHUNGEN_UEBERSICHT: "Overview of bookings",
    BUCHUNGS_ID_NICHT_GEFUNDEN: "Id of booking not found",
    BUCHUNGS_UEBERSICHT: "Overview of bookings",
    BUCHUNGSNAME: "Booking name",
    BUCHUNGSANFRAGE_ABSCHICKEN: "Send the booking request",
    BUCHUNGSANFRAGE_ERFOLGREICH_ERSTELLT:
        "Booking request was created successfully.",
    BUCHUNGSANFRAGE_ERSTELLEN: "Create a booking request",
    BUCHUNGSANFRAGEN_VERWALTEN: "Booking request administer",
    BUCHUNGSDATEN: "Booking data",
    BUCHUNGSDATUM_GETRENNT: "Booking <br> date",
    BUCHUNGSTATUS: "State of booking",
    BUCHUNGSTATUS_GETRENNT: "State of <br> booking",
    BUCHUNGSUNTERLAGE_AENDERN: "Change document of booking",
    BUCHUNGSUNTERLAGE_ARCHIVIEREN: "Delete document of booking",
    BUCHUNGSUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Booking document was updated successfully.",
    BUCHUNGSUNTERLAGE_ERFOLGREICH_ARCHIVIERT:
        "Booking document was deleted successfully.",
    BUCHUNGSUNTERLAGE_ERFOLGREICH_ERSTELLT:
        "Booking document was created successfully.",
    BUCHUNGSUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Booking document was updated successfully.",
    BUCHUNGSUNTERLAGEN: "Booking documents",
    BUCHUNGSUNTERLAGE_HINZUFUEGEN: "Add document of container",
    BUCHUNGSUNTERLAGEN_KONFIGURIEREN: "Booking documents",
    BUCHUNGSZEITRAUM: "Period of booking",
    CONTAINER_AKTUALISIEREN: "Change shipping",
    CONTAINER_BEZEICHNUNG: "Container designation",
    CONTAINER_BUCHUNGSUNTERLAGEN_KONFIGURIEREN: "Container booking documents",
    CONTAINER_BUCHEN: "Book a shipping",
    CONTAINER_BUCHUNGSUNTERLAGEN: "Container booking documents",
    CONTAINER_BUCHUNGSUNTERLAGE_ARCHIVIEREN:
        "Archive container booking document",
    CONTAINER_BUCHUNGSUNTERLAGE_AENDERN: "Change container booking document",
    CONTAINER_BUCHUNGSUNTERLAGE_ANZEIGEN: "Show container booking document",
    CONTAINERBUCHUNGSUNTERLAGE_ERFOLGREICH_ARCHIVIERT:
        "Container booking document successfully deleted",
    CONTAINERBUCHUNGSUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Container booking document successfully updated",
    CONTAINER_BUCHUNGSUNTERLAGEN_KONFIGURIEREN: "Container booking documents",
    CONTAINER_BUCHUNGSUNTERLAGEN_KONFIGURIEREN_TITLE:
        "Configure container booking documents",
    CONTAINER_DATEN: "Data of shipping",
    CONTAINER_DETAILS: "Shipping details",
    CONTAINER_ENTFERNTER_EBENE: "Delete container of removed level",
    CONTAINER_ERFOLGREICH_AKTUALISIERT: "Container was updated successfully",
    CONTAINER_ERFOLGREICH_ARCHIVIERT: "Container was deleted successfully",
    CONTAINER_ERFOLGREICH_ERSTELLT: "Container was created successfully.",
    CONTAINER_ERFOLGREICH_GEKUENDIGT: "Container was termained successfully",
    CONTAINER_ARCHIVIEREN: "Delete container",
    CONTAINER_HINZUFUEGEN: "Add container",
    CONTAINER_GENEHMIGEN: "Approve the container",
    CONTAINER_KONFIGURIEREN: "Container- administration",
    CONTAINER_KUENDIGEN: "Terminate containers",
    CONTAINER_MODUL: "Container module",
    CONTAINER_NAME: "Container name",
    CONTAINER_NAME_GETRENNT: "Container <br>name",
    CONTAINER_ID_NICHT_GEFUNDEN: "Container id not found.",
    CONTAINER_STATUS_GETRENNT: "State of <br> container",
    CONTAINER_TYP: "Type of container",
    CONTAINER_TYP_GETRENNT: "Type of<br>container",
    CONTAINER_UEBERSICHT: "Overview of container",
    CONTAINER_UNTERLAGEN_KONFIGURIEREN: "Container documents",
    CONTAINER_VERLAENGERN: "Extend container",
    CONTAINERANZAHL: "Count of container",
    CONTAINERANZAHL_GETRENNT: "Count of<br>container",
    CONTAINERBILD_ANZEIGEN: "Show image",
    CONTAINERBUCHUNGEN: "Container bookings",
    CONTAINERBURG: "Container castle",
    CONTAINERBURG_AKTUALISIEREN: "Update of container castle",
    CONTAINERBURG_ARCHIVIEREN: "Delete of container castle",
    CONTAINERBURG_BEZEICHNUNG: "Label of container castle",
    CONTAINERBURG_ERFOLGREICH_AKTUALISIERT:
        "Container castle was update successfully",
    CONTAINERBURG_ERFOLGREICH_ARCHIVIERT:
        "Container castle was deleted successfully",
    CONTAINERBURG_ERFOLGREICH_ERSTELLT:
        "Container castle was created successfully.",
    CONTAINERBURG_EXPORTIEREN: "Export of container castle",
    CONTAINERBURG_HINZUFUEGEN: "Add container castle",
    CONTAINERBURG_ID_NICHT_GEFUNDEN: "Container castle id not found",
    CONTAINERBURG_KONFIGURIEREN: "Container castle administration",
    CONTAINERBURG_NUMMER: "Number",
    CONTAINERBURGEN: "Container castle",
    CONTAINEREINHEIT: "Container unit",
    CONTAINER_LOESCHEN: "Delete container",
    CONTAINER_NAME: "Containername",
    CONTAINERNAME: "Containername",
    CONTAINERNUMMER_LIEFERANT: "Container number (Supplier)",
    CONTAINERTYP: "Container type",
    CONTAINERUNTERLAGEN_AENDERN: "Edit container document",
    CONTAINERUNTERLAGE_AKTUALISIEREN: "Update container document",
    CONTAINERUNTERLAGE_ANZEIGEN: "Show container document",
    CONTAINERUNTERLAGE_ARCHIVIEREN: "Archive container docucment",
    CONTAINERUNTERLAGE_ERFOLGREICH_AKTUALISIERT:
        "Container document was updated successfully.",
    CONTAINERUNTERLAGE_ERFOLGREICH_ARCHIVIERT:
        "Container document was deleted successfully",
    CONTAINERUNTERLAGE_ERFOLGREICH_ERSTELLT:
        "Container document was created successfully.",
    CONTAINERUNTERLAGE_HINZUFUEGEN: "Add container document",
    DATEI_AUSWAEHLEN: "Choose file",
    DOKUMENT: "Document",
    DOKUMENT_ARCHIVIEREN: "Delete document",
    DOKUMENT_MODAL_ARCHIVIEREN: "Would you like to delete the document really?",
    DOWNLOADNAME: "Downloadname",
    EBENE: "Levels",
    EBENEN_ANZAHL: "Count of levels",
    EINSTELLUNGEN: "Settings",
    EMAIL: "Emailadress",
    EUFECB_ERFOLGREICH_ERSTELLT:
        "Container booking document successfully created",
    EXPORT_TOAST:
        "Download has been started as a background operation during your work in ALGO. Please do not close ALGO until the download is completed. ",
    FEHLENDE_RECHTE: "Missing permissions",
    FREI: "Free",
    GENEHMIGE_CONTAINER: "Approve selected Container",
    GRUENDE_DER_AENDERUNG: "Reasons of change",
    GRUENDE_DER_BUCHUNG: "Reasons of booking",
    GRUENDE_DER_STORNIERUNG: "Reasons of cancel",
    GRUENDE_DER_VERLAENGERUNG: "Reasons of extend",
    HILFE_TEXT_BLAU: "Sanitary container",
    HILFE_TEXT_GELB:
        "Container has already been canceled and will be free again",
    HILFE_TEXT_GRUEN: "Container is freely available",
    HILFE_TEXT_LINKS: "Left side of the plane",
    HILFE_TEXT_ROT: "Booked containers",
    HILFE_TEXT_RECHTS: "Right side of the plane",
    HOCHGELADENE_UNTERLAGE: "Uploaded document",
    INFO_TITEL: "Information of container castle view",
    INVALID_FORM: "Enter fill all input fields.",
    JA: "Yes",
    KEIN_BILD_VORHANDEN: "No picture available",
    KEIN_PDF_FORMAT: "Please upload a document by pdf format",
    KEINE_AUSSTATTUNG: "No equipment available",
    KEINE_CONTAINER_ZU_GENEHMIGEN: "No containers to approve",
    KEINE_CONTAINER_ZU_KUENDIGEN: "No containers to cancel",
    KEINE_CONTAINER_ZU_VERLAENGERN: "No container to extend",
    KEINE_SONDERAUSSTATTUNG: "No special equipment booked",
    KEINE_UNTERLAGEN: "No documents available",
    KEINE_VORLAGE_HOCHGELADEN: "No template was uploaded",
    KONTAKT: "Contact",
    KONTAKT_DATEN: "Contact details",
    KONTAKTDATEN: "Contact details of container management",
    KONTAKTDATEN_BUCHUNG: "Contact details of container",
    KONTAKTDATEN_GETRENNT: "Contact<br>details",
    KONTAKTPERSON: "Person of Contact",
    KUENDIGUNG_BESTAETIGEN: "Accept termination",
    KUENGIGUNG_BIS_AUSWAEHLEN: "Termination until",
    KUENDIGUNGSTERMIN: "Termination date",
    LEISTUNGSVERZEICHNIS_NUMMER: "BOQ-No",
    LINKE_SEITE: "Left page",
    LINKER_NACHBAR: "Left neighbour",
    LV_NUMMER: "LV-No.",
    MAIL_AN_BENUTZER: "Mail on person of booking",
    MIETGEBUEHREN: "Rental fees",
    MODULVERANTWORTLICHER_MAIL: "Mail of responsible",
    MODULVERANTWORTLICHER_TELEFONNUMMER: "Phonenumber of responsible",
    NACHRICHT: "Message",
    NACHRICHT_MAIL:
        "The message is sent with the email and is considered information for the person making the booking or the person responsible for the module",
    NACHRICHT_ZUR_GENEHMIGUNG: "Message of approve",
    NACHRICHT_ZUR_KUENDIGUNG: "Message of canceled",
    NACHRICHT_ZUR_VERLAENGERUNG: "Message for extension",
    NAME: "Name",
    NAME_EINGEBEN: "Please enter the name",
    NEIN: "No",
    EUFEB_ANZEIGEN: "Show booking document as a PDF file",
    EUFECB_ANZEIGEN: "Show document of container booking as a PDF file",
    EUFEB_HOCHLADEN: "Upload booking document as a PDF file",
    EUFECB_HOCHLADEN: "Upload document of container booking as a PDF file",
    PERSONENANZAHL: "Count of people",
    PERSONENANZAHL_GETRENNT: "Count of<br>people",
    RECHNUNGSADRESSE: "Billing address",
    RECHNUNGSDATEN: "Data of billing",
    RECHNUNG_PER_MAIL: "Receive invoice by mail",
    RECHNUNGS_STRASSE: "Street",
    RECHNUNGS_STADT: "City",
    RECHNUNGS_POSTLEITZAHL: "Postal code",
    RECHTE_SEITE: "Right page",
    RECHTER_NACHBAR: "Right neighbour",
    SEITE: "Page",
    SONDERAUSSTATTUNG_INFO_CONTENT:
        "The following equipment is special equipment and is associated with costs:",
    STANDARD_AUSSTATTUNG_INFO_CONTENT:
        "The following equipment is standard equipment and is therefore available for all containers: ",
    SONDERAUSSTATTUNG_GETRENNT: "Special- equipment?",
    STATUS: "Status",
    STATUS_AUSWAEHLEN: "Please select state",
    SONDERAUSSTATTUNG: "Special equipment",
    TELEFONNUMMER: "Phonenumber",
    TYP: "Typ",
    UNTERNEHMEN: "Company",
    UNTERLAGE_ERFOLGREICH_ARCHIVIERT: "Document was deleted succesfully",
    UNTERLAGE_ERFOLGREICH_HINZUGEFUEGT: "Document was created successfully",
    UNTERLAGE_HINZUFUEGEN: "Add document",
    UNTERLAGE_HOCHLADEN: "Upload document",
    UNTERLAGE_NICHT_GEFUNDEN: "Document couldn't found",
    UNTERLAGEN: "Documents",
    UNTERLAGEN_ID_NICHT_GEFUNDEN: "Id of document not found",
    VERSAND_PER_MAIL: "Shipping by e-mail",
    VERSAND_PER_POST: "Shipping by post",
    VORLAGE: "Template",
    VORLAGE_ANZEIGEN: "Show template",
    VERBINDLICHE_BUCHUNGSABFRAGE: "Send binding booking request",
    VERBINDLICHE_BUCHUNGSABFRAGE_CONTENT:
        "The request results in rental fees for you, which you can find in the construction site documents.",
    VERBINDLICHE_BUCHUNGSABFRAGE_ABSCHICKEN_CONTENT:
        "Would you like to send this binding booking request?",
    VERBINDLICHE_MODULVERANTWORTLICHEN_CONTENT:
        "The booking must still be approved by the module supervisor.",
    VERBINDLICHE_MODULVERANTWORTLICHEN_ZUSAGE_CONTENT:
        "Only after approval will you receive a commitment for the bookings of the containers as well as the special equipment.",
    VERBINDLICHE_MODULVERANTWORTLICHEN_ZUSAGE_CONTENT_OHNE:
        "Only after approval will you receive a commitment for the bookings of the containers.",
    VERBINDLICHE_SONDERAUSSTATTUNG_CONTENT:
        "The entry of special equipment may incur costs, which you can find in the booking documents.",
    VERLAENGERN_BESTAETIGEN: "Confirm Extend",
    VERLAENGERN_BIS_AUSWAEHLEN: "Extend until",
    WEITERE_UNTERLAGE_HOCHLADEN: "Upload further document",
    WEITERE_UNTERLAGE_HOCHLADEN_TITLE:
        "Further document which isn't queried above",
    WIRD_NACHGEREICHT: "will be given later",
    ZEITRAUM: "Period",
    ZEITRAUM_AUSWAEHLEN: "Choose a period",
    ZEITRAUM_EINSCHRAENKUNG: "Restrict period",
    ZURUECK: "Back",
};
